<template>
  <div class="import-student">
    <div class="tab">
      <div class="back" @click="goBack()">
        <i class="iconfont icon-fanhui"></i> <span>返回</span>
      </div>
      <div class="active"><i class="iconfont icon-kaoshi"></i> 导入考生</div>
      <div class="reImport">
        <el-button type="primary" @click="importfile()">{{
          total == 0 ? '立即导入' : '重新导入'
          }}</el-button>
      </div>
    </div>
    <div class="table">
      <el-table :data="studentList" border style="width: 100%" size="small" max-height="500px">
        <el-table-column prop="importDttm" label="导入时间"> </el-table-column>
        <el-table-column prop="name" label="姓名"> </el-table-column>
        <el-table-column prop="examineeNo" label="准考证号"> </el-table-column>
        <el-table-column prop="idcardNo" label="身份证号"> </el-table-column>
        <el-table-column prop="phone" label="手机号"> </el-table-column>
      </el-table>
    </div>
    <p style="text-align: center; margin-top: 20px" v-if="total > 0">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current"
        :page-sizes="[20, 30, 50, 100]" :page-size="size" layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </p>

    <el-dialog title="批量导入考生" :visible.sync="centerDialogVisible" width="30%" center @close="dialogClose" :close-on-click-modal="false">
      <div class="import-student-dialog">
        <div class="content">
          <p class="step"><span>Step1</span></p>
          <p class="text">请先下载批量添加考生的模板，若之前已下载过模板请直接进行Step2操作</p>
          <p class="operate">
            <el-button type="primary" plain @click="downloadTemplate()">下载模版</el-button>
          </p>
        </div>
        <div class="content">
          <p class="step"><span>Step2</span></p>
          <p class="text">
            将下载好的模板内添加需要导入的考生信息，保存好后，选择考生名单的Excel表格
          </p>
          <p class="operate">
            <el-input v-model="fileName" :disabled="true" style="width: 70%"> </el-input>
            <span class="btn">
              <input type="file" name="" ref="file" :disabled="isBegin" class="file" accept=".xlsx,.xls"
                @change="handleFileChange" />
              <el-button size="medium" type="primary" plain :disabled="isBegin">选择Excel</el-button>
            </span>
          </p>
          <p v-if="isBegin" style="color: red; margin: 10px 0;text-align: center;">考试进行中无法导入考生</p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" plain @click="submitFile()" :disabled="isBegin">立即导入</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import ApisConfig from '@/apis/api.config';
export default {
  name: 'ImportStudent',
  data() {
    return {
      examId: null,
      type: null,
      total: 0,
      studentList: [],
      current: 1,
      size: 30,
      centerDialogVisible: false,
      fileName: '',
      selectedFile: null,
      isBegin: false,//是否开始考试
    };
  },
  components: {},
  created() {
    this.examId = this.$route.params.examId;
    this.getStudent();
    this.getExam();
  },
  methods: {
    async getStudent() {
      let data = {
        examId: this.examId,
        page: {
          current: this.current,
          size: this.size,
        },
      };
      const result = await this.Api.exammanage.queryExamStudentPage(data);
      this.total = result.data.total;
      if (this.total == 0) {
        // 首次新增进入
        this.centerDialogVisible = true;
        return;
      }
      this.studentList = result.data.records;
    },

    async downloadTemplate() {
      const res = await this.Api.exammanage.getStudentTemplate({}, { responseType: 'blob' });
      this.downloadFile(res);
    },
    async getExam() {
      const result = await this.Api.exammanage.queryExam({ id: this.examId });

      if (new Date().getTime() > new Date(result.data.startDttm).getTime()) {
        this.isBegin = true
        return;
      }

    },
    dialogClose() {
      this.selectedFile = null;
      this.fileName = null;
      this.$refs.file.value = ''
      // this.centerDialogVisible = false;
    },
    downloadFile(file) {
      const blob = new Blob([file]);
      const fileName = '导入模版.xlsx';
      const elink = document.createElement('a');
      elink.download = fileName;
      elink.style.display = 'none';
      elink.href = URL.createObjectURL(blob);
      document.body.appendChild(elink);
      elink.click();
      URL.revokeObjectURL(elink.href); // 释放URL 对象
      document.body.removeChild(elink);
    },

    handleFileChange(event) {
      this.selectedFile = event.target.files[0];
      this.fileName = this.selectedFile.name;
      console.log('🚀 ~ handleFileChange ~ this.selectedFile:', this.selectedFile);
    },
    submitFile() {
      if (!this.selectedFile) {
        this.$message.error('请选择文件!');
        return;
      }

      let that = this

      this.selectedFile.slice(0, 1) // only the first byte
        .arrayBuffer() // try to read
        .then(() => {
          that.uploadFile()
        })
        .catch((err) => {
    
          that.$message.success('文件已修改,请重新选择!');

          that.selectedFile = null; // 把缓存的file清空
          that.fileName = null; // 把缓存的file清空
          this.$refs.file.value = ''
          console.log('2');


        });
    },
    uploadFile() {
      // if (!this.selectedFile) {
      //   this.$message.error('请选择文件!');
      //   return;
      // }
      const formData = new FormData();
      formData.append('file', this.selectedFile);
      formData.append('examId', this.examId);

      axios
        .post(ApisConfig.examApi + '/exam/web/manage/exam/importStudents', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          if (response.code != 200) {
            this.$message.error(response.message);
            this.selectedFile = null;
            this.fileName = null;
            this.$refs.file.value = ''
            return;
          }
          this.$message.success('导入成功');

          this.selectedFile = null;
          this.fileName = null;
          this.$refs.file.value = ''
          this.centerDialogVisible = false;
          this.getStudent();
        })
        .catch((error) => {
          console.error('Error uploading file:', error);
        });
    },

    handleSizeChange(val) {
      this.size = val;
      this.current = 1;
      this.getStudent();
    },
    importfile() {
      this.centerDialogVisible = true;
    },
    handleCurrentChange(val) {
      console.log(`当前 ${val} 页`);
      this.current = val;
      this.getStudent();
    },
    goBack() {
      this.$router.push('/admin/exam-manage');
    },
  },
};
</script>

<style scoped lang="scss">
.import-student {
  overflow: hidden;
  width: 100%;

  .tab {
    height: 48px;
    margin: 20px;
    border-bottom: 1px solid #d8d8d8;
    display: flex;
    position: relative;
    margin-left: 100px;

    div {
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      margin-right: 30px;
      padding: 0 10px;
      cursor: pointer;
      bottom: -1px;
      box-sizing: border-box;

      i {
        margin-right: 3px;
      }
    }

    .active {
      border-bottom: 3px solid #3bafda;
      color: #3bafda;
    }

    .back {
      position: absolute;
      left: -80px;
      display: flex;
      align-items: center;
    }

    .reImport {
      position: absolute;
      right: 0;
    }
  }
}

::v-deep .el-form-item {
  margin-bottom: 20px;
}

.red {
  color: red;
}

.btn-padding {
  padding: 12px 20px;
}

.import-student-dialog {
  width: 100%;
  height: 280px;

  .content {
    overflow: hidden;
    margin-bottom: 20px;

    .step {
      height: 22px;
      box-sizing: border-box;
      border-bottom: 1px solid #3bafda;

      span {
        display: inline-block;
        width: 80px;
        height: 22px;
        color: #fff;
        background: url('../../../assets/images/step.png') no-repeat center;
        background-size: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .text {
      margin: 10px 0 20px 0;
    }

    .operate {
      text-align: center;
      display: flex;
      justify-content: center;

      .btn {
        display: inline-block;
        overflow: hidden;
        position: relative;
        width: 30%;

        .file {
          position: absolute;
          height: 40px;
          z-index: 1;
          opacity: 0;
        }
      }
    }
  }
}

::v-deep .table {
  margin: 0 20px;
  overflow: hidden;

  .el-table--small .el-table__cell {
    text-align: center;
  }

  .el-table__header tr th {
    background-color: #f2f2f2;
    color: #000;
    /* 设置表头单元格的背景色 */
  }
}
</style>
